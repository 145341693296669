import React, { Component } from 'react';
import _ from 'lodash';
import portfolios from '../json/portfolios.json';

const renderImg = (shots) => {
  const imgObjArray = [];
  shots.forEach((element) => {
    imgObjArray.push(
      <figure>
        <img
          className={`shots--${element.type} shots-frame`}
          src={element.url}
          alt={element.description}
          title={element.description}
        />
        <figcaption>{element.caption}</figcaption>
      </figure>,
    );
  });
  return imgObjArray;
};

const renderPortfolio = () => {
  const workObjArray = [];
  let { work } = portfolios;
  if (!work) {
    return <div>Loading</div>;
  }
  work = _.orderBy(work, ['endDate'], ['desc']);
  work.forEach((element) => {
    workObjArray.push(
      <div className="portfolio">
        <h5 className="mb-2">{element.name}</h5>
        <em>
          <div>{element.summary}</div>
          <div>{element.technology}</div>
        </em>
        <div>{renderImg(element.shots)}</div>
      </div>,
    );
  });
  return workObjArray;
};

export default (props) => (
  <main className="portfolio-container">
    <div>
      <h3>Portfolios</h3>
      <p>
        Here are some of the works which I have done as a freelancer. To know more about my recent
        work, kindly see my resume's experience section.
      </p>
    </div>
    <div className="work-container">{renderPortfolio()}</div>
  </main>
);
