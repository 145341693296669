import React from 'react';
import $ from 'jquery';

const toggleSwitch = (e) => {
  if ($('.btn-switch').is('.left')) {
    $('.moon').hide();
    $('.sun').show();
    $('.btn-switch').removeClass('left');
    $('.btn-switch').addClass('right');
    $('.app-container').attr('dark', 'false');
    localStorage.setItem('dark', 'false');
  } else {
    $('.sun').hide();
    $('.moon').show();
    $('.btn-switch').removeClass('right');
    $('.btn-switch').addClass('left');
    $('.app-container').attr('dark', 'true');
    localStorage.setItem('dark', 'true');
  }
};

const ModeToggler = (props) => {
  let mode = localStorage.getItem('dark');
  mode = mode || 'true';
  const moonStyle = mode === 'true' ? null : { display: 'none' };
  const sunStyle = mode === 'true' ? { display: 'none' } : null;
  const btnSwitchClass = mode === 'true' ? 'left' : 'right';
  return (
    <div className="mode-toggler" onClick={toggleSwitch}>
      <span className="moon" style={moonStyle} />
      <div className={`btn-switch ${btnSwitchClass}`} />
      <span className="sun" style={sunStyle} />
    </div>
  );
};

export default ModeToggler;
