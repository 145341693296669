import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import logo from '../img/logo.svg';
import nameLogo from '../img/shamsi-name.svg';
import ModeToggler from './ModeToggler';

const NavigationBottom = (props) => (
  <nav className="navbar bottom">
    <ul className="navbar-nav bottom">
      <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
        <Link className="navbar-brand" to="/">
          <img src={logo} alt="Your Project" className="brand-logo" />
          <img src={nameLogo} alt="Your Project" className="brand-name" />
        </Link>
      </li>
      <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
        <Link className="nav-link" to="/work">
          Work
        </Link>
      </li>
      <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
        <Link className="nav-link" to="/resume">
          Resume
        </Link>
      </li>
      <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
        <a className="nav-link" target="_blank" href="https://blog.shamsi.dev">
          Blog
        </a>
      </li>
      <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
        <Link className="nav-link" to="/speak">
          Speak
        </Link>
      </li>
      <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
        <Link className="nav-link" to="/teach">
          Teach
        </Link>
      </li>
    </ul>
  </nav>
);

export default NavigationBottom;
