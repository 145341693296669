import React from "react";

export default () => (
  <div className="techhelp-container">
    <blockquote>
      Before you ask, you must think, research and try to do it on your own.
    </blockquote>
    <p>
      I'm always happy to help. I do dedicate my time on answer wide range of
      technical question. The response time can be around of couple of days to a
      week. However, I urge you to first try to come of a solution at your own.
      There are lots of forums and communities where you can get help from.
      Please do not expect me to do your assignments or homework.
    </p>
    <div className="highlight-question">
      <p>Popular tech. communities where you can get help.</p>
      <a href="https://stackoverflow.com" target="_blank" rel="noreferrer">
        <button type="button" className="btn ml-3 mt-2">
          StackOverflow
        </button>
      </a>
      <a
        href="https://www.sitepoint.com/community/"
        target="_blank"
        rel="noreferrer"
      >
        <button type="button" className="btn ml-3 mt-2">
          Site Point Community
        </button>
      </a>
      <a href="https://www.codenewbie.org/" target="_blank" rel="noreferrer">
        <button type="button" className="btn ml-3 mt-2">
          Code Newbie
        </button>
      </a>
      <a
        href="https://www.digitalocean.com/community"
        target="_blank"
        rel="noreferrer"
      >
        <button type="button" className="btn ml-3 mt-2">
          Digital Ocean Community
        </button>
      </a>
      <a href="https://www.reddit.com/" target="_blank" rel="noreferrer">
        <button type="button" className="btn ml-3 mt-2">
          Reddit
        </button>
      </a>
      <a href="https://dev.to/" target="_blank" rel="noreferrer">
        <button type="button" className="btn ml-3 mt-2">
          Dev.to
        </button>
      </a>
      <p>
        <a
          href="https://stackoverflow.com/help/how-to-ask"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          How do I ask a good question?{" "}
        </a>
      </p>
    </div>
    <p>
      <a
        target="_blank"
        rel="noreferrer"
        href="mailto:muhammedsalman@shamsi.dev"
      >
        Drop me a mail
      </a>{" "}
      with appropriate details, I'll get back to you.
    </p>
  </div>
);
