import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import momentDuration from 'moment-duration-format';
import download from '../img/download.svg';
import resume from '../json/resume.json';

momentDuration(moment);

const renderProfileSummary = () => (
  <div className="profile-summary">
    <ul>
      <li>
        <strong>
          <em>5+ years </em>
        </strong>
        of experience in the field of
        <strong>
          <em> web development.</em>
        </strong>
      </li>
      <li>
        <strong>
          <em>5.5+ years </em>
        </strong>
        of experience in teaching
        <strong>
          <em> programming, database and web technologies.</em>
        </strong>
      </li>
      <li>
        Completed
        <strong>
          <em> 11 MOOC’s on Coursera, 13 on Udemy, 5 on LinkedIn Learning </em>
        </strong>
        which proves me a constantly motivated learner possessing in demand technical skills.
      </li>
      <li>
        <strong>
          <em>4k+ Stack Overflow </em>
        </strong>
        reputation and
        <strong>
          <em> 35+ Github </em>
        </strong>
        repository which demonstrate my contribution to open source community.
      </li>
    </ul>
  </div>
);

const renderLanguages = () => {
  const languages = _.orderBy(resume.languages, ['fluency'], ['desc']);
  const langObjArray = [];
  languages.forEach((lang) => {
    langObjArray.push(
      <div>
        <span>
          {lang.language}
          {' '}
          :
          {' '}
        </span>
        <span>{lang.fluency}</span>
      </div>,
    );
  });
  return langObjArray;
};

const renderMoocs = () => {
  const moocs = _.orderBy(resume.mooc, ['date'], ['desc']);
  const moocsObjArray = [];
  moocs.forEach((mooc) => {
    moocsObjArray.push(
      <div className="mooc">
        <div>
          <strong>{mooc.title}</strong>
        </div>
        <div>
          <span>
            {mooc.site}
            {', '}
          </span>
          <span>{mooc.institute == 'NA' ? '' : `${mooc.institute}, `}</span>
          <span>{mooc.grade == 'NA' ? '' : `${mooc.grade}, `}</span>
          <span>{mooc.certificateNo == 'NA' ? '' : `${mooc.certificateNo}, `}</span>
          <span>
            <strong>{moment(mooc.date, 'YYYY-MM-DD').format("MMM 'YY")}</strong>
          </span>
        </div>
      </div>,
    );
  });
  return moocsObjArray;
};

const renderExperience = () => {
  const work = _.orderBy(resume.work, ['startDate'], ['desc']);
  const workObjArray = [];
  work.forEach((experience) => {
    workObjArray.push(
      <div className="work">
        <div>
          <strong>
            {experience.position}
            {', '}
          </strong>
          <em>
            <span>
              {experience.company}
              {', '}
            </span>
            <span>
              {moment(experience.startDate, 'YYYY-MM-DD').format("MMM 'YY")}
              {' - '}
              {experience.endDate
                ? moment(experience.endDate, 'YYYY-MM-DD').format("MMM 'YY")
                : 'Present'}
              {', '}
            </span>
            <span>
              {moment
                .duration(
                  (experience.endDate ? moment(experience.endDate, 'YYYY-MM-DD') : moment()).diff(
                    moment(experience.startDate, 'YYYY-MM-DD'),
                  ),
                )
                .format('Y [yr] M [mo]')}
            </span>
          </em>
        </div>
        <div>
          <span>{experience.summary}</span>
        </div>
      </div>,
    );
  });
  return workObjArray;
};

const renderEducation = () => {
  const education = _.orderBy(resume.education, ['startDate'], ['desc']);
  const educationObjArray = [];
  education.forEach((degree) => {
    educationObjArray.push(
      <div className="degree">
        <div>
          <strong>
            {degree.studyType}
            {' - '}
          </strong>
          <span>
            <strong>
              <em>
                {degree.area}
                {', '}
              </em>
            </strong>
          </span>
          <span>
            <strong>
              <em>{degree.endDate.slice(0, 4)}</em>
            </strong>
          </span>
        </div>
        <div>
          <span>
            {degree.class}
            {', '}
          </span>
          <span>{degree.institution}</span>
        </div>
      </div>,
    );
  });
  return educationObjArray;
};

const renderSkills = (category) => {
  const skills = _.orderBy(category, ['ratings', 'name'], ['desc', 'asc']);
  const skillsObjArray = [];
  let tempObjArray = [];
  let count = 0;
  skills.forEach((element) => {
    count += 1;
    tempObjArray.push(
      <div className="progress-container">
        <div>{element.name}</div>
        <div>
          <progress max="10" value={element.ratings} />
        </div>
      </div>,
    );
    if (count % 1 == 0) {
      skillsObjArray.push(<section>{tempObjArray}</section>);
      count = 0;
      tempObjArray = [];
    }
  });
  if (count > 0) skillsObjArray.push(<section>{tempObjArray}</section>);

  return skillsObjArray;
};
const renderSkillsByProperty = (property) => {
  let skills = [];
  _.forEach(resume.skills, (category) => {
    skills.push(_.filter(category, (item) => item[property]));
  });
  skills = _.flatten(_.reject(skills, (item) => _.isEmpty(item)));
  return renderSkills(skills);
};
const Resume = (props) => (
  <main className="resume-container">
    <div className="highlight-question">
      <p>
        <strong>Muhammed Salman Shamsi</strong>
      </p>
      <a target="_blank" rel="noreferrer" href="mailto:muhammedsalman@shamsi.dev">
        <button className="btn ml-3 mt-1 mb-3" type="button">
          Ask For Resume
        </button>
      </a>
      {renderProfileSummary()}
    </div>
    <section>
      <h4>Skills</h4>
      <div className="skills-container">
        <div>
          <h5>Day to Day Comfort</h5>
          <div className="skills" data-attribute="daytoday">
            {renderSkillsByProperty('daytoday')}
          </div>
        </div>
        <div>
          <h5>Expertise</h5>
          <div className="skills" data-attribute="expertise">
            {renderSkillsByProperty('expertise')}
          </div>
        </div>
      </div>
    </section>
    <div className="mt-1">
      <Link className="" to="/skills">
        <button className="btn" type="button">
          I want to see more!
        </button>
      </Link>
    </div>
    <hr />
    <section>
      <h4>Languages</h4>
      <div className="languages">{renderLanguages()}</div>
    </section>
    <hr />
    <section>
      <h4>MOOC’s / Certification</h4>
      <div className="moocs-container">{renderMoocs()}</div>
    </section>
    <hr />
    <section>
      <h4>Experience</h4>
      <div className="experience-container">{renderExperience()}</div>
    </section>
    <hr />
    <section>
      <h4>Education</h4>
      <div className="education-container">{renderEducation()}</div>
    </section>
  </main>
);
export default Resume;
