import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

export default (props) => {
  const mainContentList = [
    'Passionate Builder',
    'Multi Discipline',
    'Self Motivated Learner',
    'Community Contributor',
    'The Debugger ',
    'Interested?',
  ];

  const descriptionList = [
    'I love to create, teach and design web apps.',
    'As a Fullstack developer, I do whatever it takes to build an App.',
    "I'm a persistent learner. I cannot resist my temptation to learn things.",
    'I dedicate my time for helping the developers and students communities.',
    'Got a bug huh? I will not be at ease till I found out where it is.',
    'If you get an exciting project to work on, get in touch!',
  ];

  const [mainContent, setMainContent] = useState(mainContentList[0]);
  const [description, setDescription] = useState(descriptionList[0]);
  const [count, setCount] = useState(0);
  const [mainContentStyleObj, setMainContentStyleObj] = useState({});
  const [descStyleObj, setDescStyleObj] = useState({});

  useEffect(() => {
    const interval = setInterval(() => {
      setMainContent(mainContentList[count]);
      setDescription(descriptionList[count]);
      setMainContentStyleObj({
        width: `${mainContentList[count].length}ch`,
        animation: `type2 ${mainContentList[count].length * 0.09}s steps(${
          mainContentList[count].length
        }) 1 normal forwards,
      blink-caret 1s steps(${mainContentList[count].length}) infinite normal forwards`,
        visibility: 'visible',
      });
      setDescStyleObj({
        animation: 'fade-in 1s linear 1 normal forwards',
        visibility: 'visible',
      });
      if (count < mainContentList.length - 1) {
        setCount(count + 1);
      } else {
        setCount(0);
      }
    }, 4500);

    return () => {
      clearInterval(interval);
    };
  }, [count]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (count) {
        setMainContentStyleObj({
          width: `${mainContentList[count - 1].length}ch`,
          animation: 'fade-out 1s linear 1 normal',
        });
        setDescStyleObj({
          animation: 'fade-out 1s linear 1 normal',
        });
      }
    }, 3300);
    return () => {
      clearInterval(interval);
    };
  }, [count]);

  useEffect(() => {
    const interval = setInterval(() => {
      setMainContentStyleObj({
        visibility: 'hidden',
      });
      setDescStyleObj({
        visibility: 'hidden',
      });
    }, 4300);
    return () => {
      clearInterval(interval);
    };
  }, [count]);

  return (
    <div className="home-container">
      <blockquote className="fade-in">Let's Build Something Wonderful Together</blockquote>
      <h3 className="typewriter">
        <p>
          Hi! I'm Muhammed
          {' '}
          <span className="fname">Salman</span>
          {' '}
          <span className="sname">Shamsi.</span>
        </p>
        <p>
          <span className="fname">Salman</span>
          {' '}
          Shamsi.
        </p>
      </h3>
      <div className="featured">
        <div className="feature-content-container">
          <div className="main-content" style={mainContentStyleObj}>
            {mainContent}
          </div>
          <p className="description" style={descStyleObj}>
            {description}
          </p>
        </div>
      </div>
      <div className="about-section">
        <div>
          <h3>About Me</h3>
          <p>
            Muhammed Salman Shamsi is a passionate developer & educator consistently recognized by
            his peers, students and mentors for troubleshooting skills and effectively resolving
            challenging issues. He has 5+ years of experience in the field of web development. He
            has also worked as an Assistant Professor for around 5.5 years. His expertise in
            teaching is in the field of Programming, Database and Web Technologies.
            <br />
            <strong>TLDR: </strong>
            He loves to create, teach and design web apps and is passionate about technology.
          </p>
        </div>
      </div>
      <div className="button-container">
        <Link to="/resume">
          <button className="btn">Know more</button>
        </Link>
        <Link to="/work">
          <button className="btn">Projects</button>
        </Link>
        <a target="_blank" href="mailto:muhammedsalman@shamsi.dev">
          <button className="btn">Contact me!</button>
        </a>
      </div>
    </div>
  );
};
