import React, { Component } from 'react';
import moment from 'moment';
import _ from 'lodash';
import Event from './Event';
import events from '../json/events.json';

class Speak extends Component {
  constructor(props) {
    super(props);
    this.state = {
      events,
    };
  }

  isThereAnActiveEvent() {
    let { events } = this.state.events;
    if (_.isEmpty(events)) {
      return false;
    }
    events = _.orderBy(events, ['startDate'], ['desc']);
    const linkExpiry = events[0];
    const expiryDate = moment(linkExpiry, 'YYYY-MM-DD HH:mm:ss', true);
    const today = moment();
    if (expiryDate.isSameOrAfter(today)) {
      return true;
    }
    return false;
  }

  renderEvents() {
    const eventObjArray = [];
    let { events } = this.state.events;
    if (_.isEmpty(events)) {
      return <div>Loading</div>;
    }
    events = _.orderBy(events, ['startDate'], ['desc']);
    events.forEach((element) => {
      eventObjArray.push(<Event event={element} />);
    });
    return eventObjArray;
  }

  render() {
    let noUpcomingEvents = '';
    if (!this.isThereAnActiveEvent()) {
      noUpcomingEvents = (
        <div className="highlight-question">
          There is currently no upcoming event.
        </div>
      );
    }

    return (
      <main className="speak-container">
        <div>
          <blockquote>Willing to share eager to learn</blockquote>
          <p>
            I keep sharing my love for my passion, technology & causes. I am an
            experienced educator involved in teaching since 2014. See below for
            list of upcoming and past events or engagements. If you'd like to
            invite me to speak at your platform, then kindly drop me a mail with
            appropriate details.
          </p>
        </div>
        {noUpcomingEvents}
        <div className="events-container">{this.renderEvents()}</div>
      </main>
    );
  }
}
export default Speak;
