import React from 'react';
import moment from 'moment';
import calendar from '../img/calendar.svg';
import clock from '../img/clock.svg';
import cash from '../img/cash.svg';
import mapPin from '../img/map-pin.svg';

const renderLink = ({ link, linkExpiry, startDate }) => {
  const expiryDate = moment(linkExpiry, 'YYYY-MM-DD HH:mm:ss', true);
  const today = moment();
  const linkString = link?link:'mailto:contact@shamsi.dev';
  if (expiryDate.isValid()) {
    if (expiryDate.isSameOrAfter(today)) {
      return (
        <a href={linkString}>
          <button className="btn">Book</button>
        </a>
      );
    }
    return <div>Booking Closed</div>;
  }

  const eventStart = moment(startDate, 'YYYY-MM-DD', true);
  if (eventStart.isValid()) {
    if (eventStart.isSameOrAfter(today)) {
      return (
        <a href={linkString}>
          <button className="btn">Book</button>
        </a>
      );
    }
    return <div>Booking Closed</div>;
  }
  return <div>Event Date Invalid</div>;
};

export default ({ event }) => (
  <div className="event-card">
    <div className="card-content">
      <div className="card-title"><span>{event.name}</span></div>
      <section className="top">
        <div className="start-date">
          <div className="month">
            {moment(event.startDate).format('MMM YYYY')}
          </div>
          <div className="date">{moment(event.startDate).format('DD')}</div>
          <div className="day">{moment(event.startDate).format('ddd')}</div>
        </div>
        <div className="event-dates">
          <div>
            <img
              src={clock}
              className="event-icons"
              alt="Start Time"
              title="Start Time"
            />
            {event.startTime}
            {' '}
            -
            {' '}
            {event.endTime}
            {' '}
            {event.timeZone}
          </div>
          <div>
            <img
              src={cash}
              className="event-icons"
              alt="Charges"
              title="Charges"
            />
            {event.amount}
          </div>
          <div>
            <img
              src={calendar}
              className="event-icons"
              alt="End Date"
              title="End Date"
            />
            {moment(event.endDate).format('DD MMM, YYYY')}
            {' '}
            (End Date)
          </div>
          <div>
            <img
              src={mapPin}
              className="event-icons"
              alt="Venue"
              title="Venue"
            />
            {event.location.city}
            ,
            {' '}
            {event.location.state}
            ,
            {' '}
            {event.location.country}
          </div>
        </div>
      </section>
      <section className="bottom">
        <div className="summary">{event.summary}</div>
        <div className="tech">{event.technology}</div>
        <div>{renderLink(event)}</div>
      </section>
    </div>
  </div>
);
