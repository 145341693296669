import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import logo from '../img/logo.svg';
import nameLogo from '../img/shamsi-name.svg';
import linkedIn from '../img/linkedin.svg';
import mail from '../img/mail.svg';
import so from '../img/stackoverflow.svg';
import codepen from '../img/codepen.svg';
import github from '../img/github.svg';
import ModeToggler from './ModeToggler';

const Navigation = (props) => (
  <nav className="navbar">
    <ul className="navbar-nav">
      <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
        <Link className="navbar-brand" to="/">
          <img src={logo} alt="Your Project" className="brand-logo" />
          <img src={nameLogo} alt="Your Project" className="brand-name" />
        </Link>
      </li>
      <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
        <a className="nav-link" target="_blank" href="mailto:muhammedsalman@shamsi.dev">
          <img src={mail} className="nav-icon" alt="Mail" title="muhammedsalman@shamsi.dev" />
        </a>
      </li>
      <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
        <a className="nav-link" target="_blank" href="https://linkedin.com/in/muhammedsalmanshamsi">
          <img src={linkedIn} className="nav-icon" alt="LinkedIn" title="LinkedIn" />
        </a>
      </li>
      <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
        <a className="nav-link" target="_blank" href="https://stackoverflow.com/users/3212574">
          <img src={so} className="nav-icon" alt="StackOverflow" title="StackOverflow" />
        </a>
      </li>
      <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
        <a className="nav-link" target="_blank" href="https://github.com/muhammed-salman/">
          <img src={github} className="nav-icon" alt="GitHub" title="GitHub" />
        </a>
      </li>
      <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
        <a className="nav-link" target="_blank" href="https://codepen.io/muhammedsalmanshamsi/">
          <img src={codepen} className="nav-icon" alt="Codepen" title="Codepen" />
        </a>
      </li>
    </ul>
    <nav className="navbar right">
      <ul className="navbar-nav">
        <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show" />
        <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
          <Link className="nav-link" to="/work">
            Work
          </Link>
        </li>
        <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
          <Link className="nav-link" to="/resume">
            Resume
          </Link>
        </li>
        <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a className="nav-link" target="_blank" href="https://blog.shamsi.dev">
            Blog
          </a>
        </li>
        <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
          <Link className="nav-link" to="/speak">
            Speak
          </Link>
        </li>
        <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
          <Link className="nav-link" to="/teach">
            Teach
          </Link>
        </li>
      </ul>
      <ModeToggler />
    </nav>
  </nav>
);

export default Navigation;
