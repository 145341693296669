import React from 'react';
import { Link } from 'react-router-dom';
import NavigationBottom from './NavigationBottom';
import heart from '../img/heart.svg';

const Footer = (props) => (
  <footer className="footer">
    <NavigationBottom />
    <div className="copyright">
      &copy;
      {' '}
      {(new Date()).getFullYear()}
      &nbsp; Design & Built with
      {' '}
      <span className="beating-heart">
        <img src={heart} alt="Love" title="Love" />
      </span>
      {' '}
      by Me
    </div>
  </footer>
);
export default Footer;

