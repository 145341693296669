import React from 'react';
import { Link } from 'react-router-dom';
import heart from '../img/heart.svg';

const Teach = (props) => (
  <main className="teach-container">
    <blockquote>Want to Learn Something Awesome? So, What You are Waiting For?</blockquote>
    <p>
        I can teach you how to build a custom web apps using modern HTML, CSS and JavaScript. 
    </p>
    <p>    
        I have the ability to consistently individualize instruction, based on learner's needs and interests.
    </p>
    <div className="highlight-question">
      <p>Want to undergo a training that suits yours need?</p> 
        <a target='_blank' href="mailto:muhammedsalman@shamsi.dev">
          <button type="button" className='btn ml-3 mt-1'>
              Contact Me
          </button>
        </a>
    </div>
    <p>
      5.5+ years of experience in teaching programming, database & web technologies.
    </p>
    <p>
      Good interpersonal and communication skills which makes me skilled at explaining complex concept easily to any novice person.  
    </p> 
    <div className="highlight-question">
      <p>As an Educationalist, I love to share and learn.</p>
        <Link to='/speak'>
          <button type="button" className='btn ml-3 mt-1'>
              Public Speaking
          </button>
        </Link>
        <Link to='/techhelp'>
          <button type="button" className='btn ml-3 mt-1'>
              Technical Help
          </button>
        </Link>
    </div>  
  </main>
);
export default Teach;
