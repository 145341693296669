import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import momentDuration from 'moment-duration-format';
import download from '../img/download.svg';
import resume from '../json/resume.json';

momentDuration(moment);

const renderSkills = (category) => {
  const skills = _.orderBy(resume.skills[category], ['ratings', 'name'], ['desc', 'asc']);
  const skillsObjArray = [];
  let tempObjArray = [];
  let count = 0;
  skills.forEach((element) => {
    count += 1;
    tempObjArray.push(
      <div className="progress-container">
        <div>{element.name}</div>
        <div>
          <progress max="10" value={element.ratings} />
        </div>
      </div>,
    );
    if (count % 1 == 0) {
      skillsObjArray.push(<section>{tempObjArray}</section>);
      count = 0;
      tempObjArray = [];
    }
  });
  if (count > 0) skillsObjArray.push(<section>{tempObjArray}</section>);

  return skillsObjArray;
};

const Skills = (props) => (
  <main className="skills-container">
    <section>
      <h3>Skills</h3>
      <div className="skills-section-container">
        <div>
          <h5>Languages / Frameworks</h5>
          <div className="skills" data-attribute="">
            {renderSkills('languages')}
          </div>
        </div>
        <div>
          <h5>Concepts</h5>
          <div className="skills" data-attribute="">
            {renderSkills('concepts')}
          </div>
        </div>
        <div>
          <h5>Tools</h5>
          <div className="skills" data-attribute="">
            {renderSkills('tools')}
          </div>
        </div>
        <div>
          <h5>Operating Systems</h5>
          <div className="skills" data-attribute="">
            {renderSkills('os')}
          </div>
        </div>
      </div>
    </section>
  </main>
);
export default Skills;
