import React from 'react';
import Header from './Header';
import Footer from './Footer';

let mode = localStorage.getItem('dark');
mode = mode || 'true';

export default ({ children }) => (
  <div className="app-container" dark={mode}>
    <Header />
    <div className="container mt-3">
      {children}
    </div>
    <Footer />
  </div>
);
