import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';
import 'bootstrap/dist/js/bootstrap.min';

import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/style.scss';
import './styles/animation.scss';
import './styles/media.scss';

import reducers from './reducers';
import AppContainer from './components/AppContainer';
import Welcome from './components/Welcome';
import Signup from './components/auth/Signup';
import Signout from './components/auth/Signout';
import Signin from './components/auth/Signin';
import App from './components/App';
import Speak from './components/Speak';
import Teach from './components/Teach';
import TechnicalHelp from './components/TechnicalHelp';
import Resume from './components/Resume';
import Portfolio from './components/Portfolio';
import Skills from './components/Skills';
import registerServiceWorker from './registerServiceWorker';


const store = createStore(
  reducers,
  {
    auth: {
      authenticated: {
        token: localStorage.getItem('token'),
        email: localStorage.getItem('email')
      },
    },
  },
  applyMiddleware(reduxThunk)
);

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <AppContainer>
        <Route path="/" exact component={Welcome} />
        <Route path="/signup" component={Signup} />
        <Route path="/home" component={App} />
        <Route path="/signout" component={Signout} />
        <Route path="/signin" component={Signin} />
        <Route path="/work" component={Portfolio} />
        <Route path="/resume" component={Resume} />
        <Route path="/speak" component={Speak} />
        <Route path="/teach" component={Teach} />
        <Route path="/techhelp" component={TechnicalHelp} />
        <Route path="/skills" component={Skills} />
      </AppContainer>
    </BrowserRouter>
  </Provider>,
  document.querySelector('#root')
);

registerServiceWorker();
